import React from 'react'

import Layout from '../layout/defaultLayout'
import Container from '../components/layout/Container';


const bedankt = () => {
  return (
    <Layout>
      <Container>
        <h1>Bedankt om ons te contacteren</h1>
        <p>We nemen zo spoedig mogelijk contact met u op.</p>
      </Container>
    </Layout>
  )
}

export default bedankt
